"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { UNIFIED_DATA_MODELS, schemaHasFixedValues, getReferenceCollectionPathForSchema, getFormula, DataBuilderFormulaType } from '@integration-app/sdk';
import { DropdownCategoryType } from '../types.mjs';
import { getLookupCollection } from '../Dropdown/Categories/Lookup.mjs';

function isSchemaAllowCustomValue(schema) {
  const schemaHasNoType = !schema?.type;
  const schemaIsObjectWithNoProperties = schema?.type === "object" && Object.keys(schema.properties ?? {}).length === 0;
  const schemaIsArrayWithNoSchemaItems = schema?.type === "array" && !schema?.items?.type;
  return schemaHasNoType || schemaIsObjectWithNoProperties || schemaIsArrayWithNoSchemaItems;
}
async function getAvailableRootCategories({
  schema,
  options,
  optionFactories,
  variablesOnly,
  hasVariables,
  client,
  connectionId,
  integrationId
}) {
  const availableCategories = [];
  const udm = schema?.referenceUdm;
  const udmSpec = udm ? UNIFIED_DATA_MODELS[udm] : void 0;
  if (!!udmSpec) {
    availableCategories.push(DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY);
  }
  const comboboxHasOptions = Array.isArray(options);
  const comboboxHasOptionFactories = (optionFactories || []).length > 0;
  const comboboxHasFixedValues = !!schema && schemaHasFixedValues(schema);
  const comboboxHasOptionList = comboboxHasOptions || comboboxHasOptionFactories || comboboxHasFixedValues;
  if (comboboxHasOptionList) {
    availableCategories.push(DropdownCategoryType.OPTIONS);
  }
  const comboboxHasReferenceCollection = !!getReferenceCollectionPathForSchema(schema);
  if (comboboxHasReferenceCollection && connectionId) {
    availableCategories.push(DropdownCategoryType.LOOKUP_OPTIONS);
  }
  if (schema && (connectionId || integrationId)) {
    const collection = await getLookupCollection({
      schema,
      client,
      connectionId,
      integrationId
    });
    if (collection?.find?.queryFields || collection?.match?.fields) {
      availableCategories.push(
        DropdownCategoryType.SELECT_FROM_COLLECTION_DYNAMICALLY
      );
    }
  }
  const schemaAllowCustomValue = isSchemaAllowCustomValue(schema);
  if (!variablesOnly && !comboboxHasOptionList && schemaAllowCustomValue) {
    availableCategories.push(DropdownCategoryType.CUSTOM);
  }
  if (hasVariables) {
    availableCategories.push(DropdownCategoryType.VARIABLE);
    availableCategories.push(DropdownCategoryType.FORMULA);
  }
  return availableCategories.length > 0 ? availableCategories : null;
}
function getCurrentCategory(availableCategories, possibleCurrentCategory, value) {
  if (possibleCurrentCategory && availableCategories.includes(possibleCurrentCategory)) {
    return possibleCurrentCategory;
  }
  return getDefaultCategory(availableCategories, value);
}
function getDefaultCategory(availableCategories, value) {
  if (availableCategories.length <= 1) {
    return availableCategories[0];
  }
  const formulaType = getFormula(value)?.type;
  if (formulaType === DataBuilderFormulaType.LOOKUP) {
    if (availableCategories.includes(
      DropdownCategoryType.SELECT_FROM_COLLECTION_DYNAMICALLY
    )) {
      return DropdownCategoryType.SELECT_FROM_COLLECTION_DYNAMICALLY;
    }
    if (availableCategories.includes(
      DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY
    )) {
      return DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY;
    }
  }
  if (formulaType === DataBuilderFormulaType.VAR) {
    return DropdownCategoryType.VARIABLE;
  }
  const generalFormulaTypes = Object.values(DataBuilderFormulaType).filter(
    (x) => ![
      DataBuilderFormulaType.TPL,
      DataBuilderFormulaType.VAR,
      DataBuilderFormulaType.RECORD
    ].includes(x)
  );
  if (availableCategories.includes(DropdownCategoryType.FORMULA) && generalFormulaTypes.includes(formulaType)) {
    return DropdownCategoryType.FORMULA;
  }
  if (availableCategories.includes(DropdownCategoryType.LOOKUP_OPTIONS)) {
    return DropdownCategoryType.LOOKUP_OPTIONS;
  }
  return DropdownCategoryType.ROOT;
}
function canUserEnterValueFromKeyboard(schema, options, optionFactories, variablesOnly, value) {
  const comboboxHasOptions = (options || []).length > 0;
  const comboboxHasOptionFactories = (optionFactories || []).length > 0;
  if (variablesOnly || comboboxHasOptions || comboboxHasOptionFactories) {
    return false;
  }
  const valueIsLookup = !!value?.$lookup;
  if (valueIsLookup) {
    return false;
  }
  if (schema && schemaHasFixedValues(schema)) {
    return !!schema.allowCustom;
  }
  if (schema?.type && ["array", "object", "boolean"].includes(schema?.type)) {
    return false;
  }
  return true;
}

export { canUserEnterValueFromKeyboard, getAvailableRootCategories, getCurrentCategory };
