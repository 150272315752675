"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { isValidElement } from 'react';
import clsx from 'clsx';
import { SvgIcon, SvgIconType } from '../SvgIcon/index.mjs';
import { Tooltip } from '../Tooltip/index.mjs';
import classes from './Notification.module.css.mjs';

function Notification({
  mode = "block",
  children,
  ...props
}) {
  if (mode === "icon") {
    const validChildren = isValidElement(children) ? children : /* @__PURE__ */ jsx(Fragment, { children });
    return /* @__PURE__ */ jsx(NotificationIcon, { ...props, children: validChildren });
  }
  return /* @__PURE__ */ jsx(NotificationBlock, { ...props, children });
}
function NotificationBlock({
  icon,
  type = "default",
  size = "md",
  className,
  children
}) {
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: clsx(
        classes.notification,
        getBlockClassnameByType(type),
        getBlockClassnameBySize(size),
        className
      ),
      children: [
        /* @__PURE__ */ jsx(
          SvgIcon,
          {
            className: classes.notification_icon,
            type: icon || getIconByType(type)
          }
        ),
        " ",
        children
      ]
    }
  );
}
const BADGE_TOOLTIP_OFFSET = [-4, 8];
function NotificationIcon({
  icon,
  type,
  children,
  className
}) {
  return /* @__PURE__ */ jsx(
    Tooltip,
    {
      tooltip: children,
      placement: "right-start",
      offset: BADGE_TOOLTIP_OFFSET,
      children: /* @__PURE__ */ jsx(
        "span",
        {
          className: clsx(
            classes.notificationIcon,
            getIconClassnameByType(type),
            className
          ),
          children: /* @__PURE__ */ jsx(
            SvgIcon,
            {
              type: icon || getIconByType(type),
              className: classes.notificationIcon_icon
            }
          )
        }
      )
    }
  );
}
function getIconByType(type) {
  switch (type) {
    case "error":
      return SvgIconType.AlertCircle;
    case "warning":
      return SvgIconType.AlertTriangle;
    case "info":
    default:
      return SvgIconType.InfoCircle;
  }
}
function getBlockClassnameByType(type) {
  switch (type) {
    case "error":
      return classes.notification__error;
    case "warning":
      return classes.notification__warning;
    case "info":
      return classes.notification__info;
    default:
      return classes.notification__default;
  }
}
function getBlockClassnameBySize(size) {
  switch (size) {
    case "sm":
      return classes.notification__sm;
    case "md":
    default:
      return classes.notification__md;
  }
}
function getIconClassnameByType(type) {
  switch (type) {
    case "error":
      return classes.notificationIcon__error;
    case "warning":
      return classes.notificationIcon__warning;
    case "info":
      return classes.notificationIcon__info;
    default:
      return classes.notificationIcon__default;
  }
}

export { Notification };
