"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useState, useRef, useEffect } from 'react';
import { getReferenceCollectionPointerForSchema, getMissingRequiredFields, extractIntegrationAppErrorData } from '@integration-app/sdk';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { useIntegrationAppClient } from '../../../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../../../contexts/integration-app-connection-context.mjs';
import { useComboBox } from '../../../context/combobox-context.mjs';
import { useComboBoxDropdownSearchContext } from '../../../context/combobox-dropdown-search.mjs';
import { OptionsParametersRequired } from './OptionsParametersRequired.mjs';
import { SvgIcon, SvgIconType } from '../../../../SvgIcon/index.mjs';
import { useDataCollectionSpec } from '../../../../../hooks/data-collections/useDataCollectionSpec.mjs';
import { useIntegrationAppIntegration } from '../../../../../contexts/integration-app-integration-context.mjs';
import { filterOptionsByLabel } from '../../../options-factories/utils.mjs';
import { useComboBoxDropdownContext } from '../../../context/combobox-dropdown-context.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../../../hooks/keyboard-events.mjs';
import deepEqual from 'fast-deep-equal';
import { useDeepEffect } from '../../../../../hooks/useDeepEffect.mjs';
import ComboBoxOption from '../../Option.mjs';
import { ParametersPanel } from './ParametersPanel.mjs';
import { v4 } from 'uuid';
import { ItemTitle } from '../../../../DropdownUI/DropdownUIElements.mjs';

function LookupOptionsCategoryContent({
  onOptionSelect
}) {
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [error, setError] = useState();
  const { searchValue } = useComboBoxDropdownSearchContext();
  const [parametersValue, setParametersValue] = useState({});
  const { schema } = useComboBox();
  const pointer = getReferenceCollectionPointerForSchema(schema);
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const { integrationId } = useIntegrationAppIntegration();
  const currentLoadOptionsId = useRef("");
  const referenceCollectionSpec = useDataCollectionSpec({
    key: pointer?.key,
    integrationId
  });
  useEffect(() => {
    if (pointer?.parameters) {
      setParametersValue(pointer.parameters);
    }
  }, [JSON.stringify(pointer)]);
  useEffect(() => {
    if (schema && connectionId) {
      void loadOptions();
    }
  }, [
    connectionId,
    JSON.stringify(schema),
    JSON.stringify(parametersValue),
    JSON.stringify(referenceCollectionSpec)
  ]);
  if (!schema) return null;
  const hasParameters = Object.keys(referenceCollectionSpec?.parametersSchema?.properties ?? {}).length > 0;
  const hasMissingParameters = getMissingRequiredFields(
    referenceCollectionSpec?.parametersSchema,
    parametersValue
  )?.length > 0;
  async function loadOptions() {
    const loadOptionsId = v4();
    currentLoadOptionsId.current = loadOptionsId;
    setLoadingOptions(true);
    setError(void 0);
    setAdditionalMessage("");
    if (hasMissingParameters) return;
    try {
      if (!connectionId) {
        throw new Error("No connection provided. Cannot load options.");
      }
      if (!referenceCollectionSpec || !pointer) {
        setOptions([]);
        return;
      }
      const connectionAccessor = client.connection(connectionId);
      let response;
      const collectionAccessor = await connectionAccessor.dataCollection(
        pointer?.key,
        parametersValue
      );
      if (referenceCollectionSpec?.list) {
        response = await collectionAccessor.list();
      } else {
        throw new Error(
          `Collection ${referenceCollectionSpec.key} does not support listing options.`
        );
      }
      if (currentLoadOptionsId.current === loadOptionsId) {
        const options2 = response.records.map((record) => {
          return {
            label: record.name ?? record.id,
            value: record.id
          };
        });
        if (response.cursor) {
          setAdditionalMessage(
            `Only the first ${options2.length} options are loaded. If your option is not in the list, please type its ID manually.`
          );
        }
        setOptions(options2);
      }
    } catch (e) {
      if (currentLoadOptionsId.current === loadOptionsId) {
        setError(extractIntegrationAppErrorData(e));
      }
    } finally {
      if (currentLoadOptionsId.current === loadOptionsId) {
        setLoadingOptions(false);
      }
    }
  }
  const filteredOptions = filterOptionsByLabel(options, searchValue);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    hasParameters && /* @__PURE__ */ jsx(
      ParametersPanel,
      {
        onChange: setParametersValue,
        schema: referenceCollectionSpec?.parametersSchema,
        value: parametersValue
      }
    ),
    /* @__PURE__ */ jsx(DropdownUI.Panel, { children: !hasMissingParameters ? loadingOptions ? /* @__PURE__ */ jsx(DropdownUI.LoadingPanel, { children: "Loading list of options..." }) : error ? /* @__PURE__ */ jsx(DropdownUI.ErrorPanel, { children: error?.message || "Something went wrong" }) : /* @__PURE__ */ jsxs(DropdownUI.List, { children: [
      /* @__PURE__ */ jsx(DropdownUI.Panel, { className: "flex-grow overflow-y-auto", children: /* @__PURE__ */ jsx(
        LookupOptionsList,
        {
          options: filteredOptions,
          onOptionSelect: (value) => onOptionSelect(value?.value)
        }
      ) }),
      additionalMessage && /* @__PURE__ */ jsx(DropdownUI.Panel, { children: /* @__PURE__ */ jsxs("div", { className: "p-4 text-warning flex gap-2 items-center", children: [
        /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.AlertCircle }),
        " ",
        additionalMessage
      ] }) })
    ] }) : /* @__PURE__ */ jsx(OptionsParametersRequired, {}) })
  ] });
}
function LookupOptionsList({
  options,
  onOptionSelect
}) {
  const { isOptionSelected } = useComboBoxDropdownContext();
  const [currentOption, setCurrentOption] = useState(null);
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_DOWN, makePreviousOptionCurrent);
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_UP, makeNextOptionCurrent);
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_SELECT, handleKeyEnter);
  useDeepEffect(() => {
    if (!options?.length) {
      return;
    }
    if (!currentOption || getOptionIndex(currentOption) == -1) {
      const selectedOption = options.find(isOptionSelected);
      if (selectedOption) {
        setCurrentOption(selectedOption);
      }
    }
  }, [options]);
  const makeOptionComparable = (option) => {
    return option && {
      ...option,
      label: option.searchLabel ?? option.label?.toString()
    };
  };
  function getOptionIndex(option) {
    return options.findIndex(
      (o) => deepEqual(makeOptionComparable(o), makeOptionComparable(option))
    );
  }
  function makeNextOptionCurrent() {
    let idx = getOptionIndex(currentOption);
    while (idx < options.length - 1) {
      idx += 1;
      const currentOption2 = options[idx];
      if (optionCanBeSelected(currentOption2)) {
        setCurrentOption(currentOption2);
        break;
      }
    }
  }
  function makePreviousOptionCurrent() {
    let idx = getOptionIndex(currentOption);
    while (idx > 0) {
      idx -= 1;
      const currentOption2 = options[idx];
      if (optionCanBeSelected(currentOption2)) {
        setCurrentOption(currentOption2);
        break;
      }
    }
  }
  function optionCanBeSelected(option) {
    return !option.isSection;
  }
  function handleKeyEnter() {
    if (currentOption) {
      handleOptionSelect(currentOption);
    }
  }
  function handleOptionSelect(option) {
    onOptionSelect(option);
  }
  return /* @__PURE__ */ jsx(DropdownUI.List, { children: /* @__PURE__ */ jsx(
    DropdownListOptions,
    {
      options,
      currentOption,
      isOptionSelected,
      handleOptionSelect
    }
  ) });
}
function DropdownListOptions({
  options,
  currentOption,
  isOptionSelected,
  handleOptionSelect
}) {
  if (!options) {
    return null;
  }
  if (options.length === 0) {
    return /* @__PURE__ */ jsx(DropdownUI.Item, { readonly: true, children: /* @__PURE__ */ jsx(ItemTitle, { children: "No results found" }) });
  }
  return /* @__PURE__ */ jsx(Fragment, { children: options.map((option, idx) => /* @__PURE__ */ jsx(
    ComboBoxOption,
    {
      option,
      selected: isOptionSelected(option),
      current: deepEqual(option, currentOption),
      onSelect: () => handleOptionSelect(option)
    },
    idx
  )) });
}

export { LookupOptionsCategoryContent };
