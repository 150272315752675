"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { Tag } from './index.mjs';
import classes from './styles.module.css.mjs';
import clsx from 'clsx';

const TagPlaceholder = forwardRef(
  function({ children, className, ...props }, ref) {
    return /* @__PURE__ */ jsx(
      Tag,
      {
        ref,
        className: clsx(
          classes.tagPlaceholder,
          !!props?.onClick && classes.tagPlaceholder__clickable,
          className
        ),
        ...props,
        children
      }
    );
  }
);

export { TagPlaceholder };
