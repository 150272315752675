"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { SvgIconType, SvgIcon } from '../SvgIcon/index.mjs';
import { Tooltip } from '../Tooltip/index.mjs';
import classes from './Button.module.css.mjs';

function getButtonClassesByVariant(variant) {
  switch (variant) {
    case "ajax":
      return classes["uikit-button--ajax"];
    case "text":
      return classes["uikit-button--text"];
    case "outline":
      return classes["uikit-button--outline"];
    case "secondary":
      return classes["uikit-button--secondary"];
    case "warning":
      return classes["uikit-button--warning"];
    case "primary":
    default:
      return classes["uikit-button--primary"];
  }
}
function getButtonClassesBySize(size) {
  switch (size) {
    case "large":
      return classes["uikit-button--large"];
    case "small":
      return classes["uikit-button--small"];
    case "medium":
    default:
      return classes["uikit-button--medium"];
  }
}
const GenericButton = forwardRef(
  ({ children, className, loading, tooltip, tooltipProps = {}, ...restProps }, ref) => {
    const button = /* @__PURE__ */ jsxs(
      "button",
      {
        className,
        type: "button",
        ...restProps,
        ref,
        "aria-busy": loading ? "true" : void 0,
        children: [
          loading && /* @__PURE__ */ jsx(ButtonSvgIcon, { type: SvgIconType.Loader }),
          children
        ]
      }
    );
    if (tooltip) {
      return /* @__PURE__ */ jsx(Tooltip, { tooltip, ...tooltipProps, children: button });
    }
    return button;
  }
);
GenericButton.displayName = "GenericButton";
const Button = forwardRef(
  ({
    variant = "primary",
    size = "medium",
    className,
    children,
    ...restProps
  }, ref) => {
    const buttonClass = clsx([
      classes["uikit-button"],
      getButtonClassesByVariant(variant),
      getButtonClassesBySize(size),
      className
    ]);
    return /* @__PURE__ */ jsx(GenericButton, { className: buttonClass, ...restProps, ref, children });
  }
);
Button.displayName = "Button";
const IconButton = forwardRef(
  ({
    children,
    size = "medium",
    className,
    isActive,
    tooltip,
    ...restProps
  }, ref) => {
    const buttonIconClass = clsx([
      classes["uikit-button"],
      classes["uikit-button--icon"],
      isActive && classes["uikit-button--icon__active"],
      getButtonClassesBySize(size),
      className
    ]);
    const button = /* @__PURE__ */ jsx("button", { className: buttonIconClass, ...restProps, ref, children });
    if (tooltip) {
      return /* @__PURE__ */ jsx(Tooltip, { tooltip, children: button });
    }
    return button;
  }
);
function ButtonSvgIcon({
  type,
  className,
  size = "normal",
  position = "start"
}) {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      type,
      className: clsx(
        classes.uikitButtonSvgIcon,
        className,
        size === "small" ? classes.uikitButtonSvgIcon_small : classes.uikitButtonSvgIcon_normal,
        position === "end" ? classes.uikitButtonSvgIcon_end : classes.uikitButtonSvgIcon_start
      )
    }
  );
}

export { Button, ButtonSvgIcon, GenericButton, IconButton };
