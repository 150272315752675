/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".Collections-module_wrapper__yMIvO {\n    max-height: 100%;\n    overflow: scroll\n}\n\n.Collections-module_createFieldSchemaForm__1ZAMc {\n    --tw-bg-opacity: 1;\n    background-color: rgb(238 238 238 / var(--tw-bg-opacity));\n    display: flex;\n    flex-direction: column;\n    gap: 0.25rem\n}\n\n.Collections-module_createFieldSchemaForm__1ZAMc label {\n    display: block;\n    cursor: pointer;\n    width: -moz-fit-content;\n    width: fit-content\n}\n\n.Collections-module_createFieldSchemaForm__1ZAMc h3,\n.Collections-module_createFieldSchema__NhbKW h3 {\n    font-size: 0.875rem;\n    line-height: 1.4;\n    font-weight: 700;\n    margin-bottom: 0.25rem\n}\n\n.Collections-module_panel__AKaFK {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    padding: 0.5rem\n}\n";
var classes = {"wrapper":"Collections-module_wrapper__yMIvO","createFieldSchemaForm":"Collections-module_createFieldSchemaForm__1ZAMc","createFieldSchema":"Collections-module_createFieldSchema__NhbKW","panel":"Collections-module_panel__AKaFK"};
styleInject(css_248z);

export { classes as default };
