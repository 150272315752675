"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import 'fuse';
import '../../SvgIcon/index.mjs';
import '@integration-app/sdk';
import '../Dropdown/categoryContentMapping.mjs';
import 'js-convert-case';
import 'react/jsx-runtime';
import 'react';
import '../../Tag/styles.module.css.mjs';

function getSchemaHint(schema) {
  function formatExample(example) {
    const exampleStr = example.toString();
    return exampleStr.length > 30 ? exampleStr.substr(0, 30) + "\u2026" : exampleStr;
  }
  if (schema.examples) {
    return schema.examples.map(formatExample).join(", ");
  } else if (schema.type == "object") {
    const propertiesNum = Object.keys(schema.properties ?? {}).length;
    if (propertiesNum > 0) {
      return `Object with ${propertiesNum} propert${propertiesNum == 1 ? "y" : "ies"}`;
    } else {
      return "Object";
    }
  } else {
    return schema.type;
  }
}

export { getSchemaHint };
