"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import usePromiseImport from 'react-use-promise';

const usePromise = usePromiseImport?.default ?? usePromiseImport;

export { usePromise as default };
