"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { filterOptionsByLabel } from './utils.mjs';
import { DropdownCategoryType } from '../types.mjs';
import { ValueType } from '../value-spec.mjs';
import { getFormulas } from '../../DataBuilder/Formula/index.mjs';
import { renderCategories } from '../Dropdown/categoryContentMapping.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, key + "" , value);
class FormulaListOptionsFactory {
  constructor() {
    __publicField(this, "categoryType", DropdownCategoryType.FORMULA);
  }
  async getOptions({ input, spec }) {
    const formulaTypes = getFormulaTypesForSpec(spec);
    const options = [];
    for (const type of formulaTypes) {
      const formulaSpec = getFormulas()[type];
      options.push({
        label: formulaSpec.name,
        value: {
          [`$${type}`]: null
        }
      });
    }
    const filteredOptions = filterOptionsByLabel(options, input);
    if (filteredOptions.length > 0) {
      filteredOptions.unshift({
        label: renderCategories[this.categoryType].title,
        isSection: true
      });
    }
    return filteredOptions;
  }
}
function getFormulaTypesForSpec(spec) {
  const result = [];
  const entries = Object.entries(getFormulas());
  for (const [formulaType, formulaSpec] of entries) {
    if (!formulaSpec.appliesToSpec) {
      continue;
    }
    if (!spec?.type || spec.type === ValueType.ANY || formulaSpec.appliesToSpec(spec)) {
      result.push(formulaType);
    }
  }
  return result;
}

export { FormulaListOptionsFactory };
