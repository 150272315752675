"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

var DropdownCategoryType = /* @__PURE__ */ ((DropdownCategoryType2) => {
  DropdownCategoryType2["ROOT"] = "root";
  DropdownCategoryType2["VARIABLE"] = "variable";
  DropdownCategoryType2["OPTIONS"] = "options";
  DropdownCategoryType2["FORMULA"] = "formula";
  DropdownCategoryType2["CUSTOM"] = "custom";
  DropdownCategoryType2["LOOKUP_OPTIONS"] = "lookup_options";
  DropdownCategoryType2["SELECT_FROM_COLLECTION_DYNAMICALLY"] = "select_from_collection_dynamically";
  DropdownCategoryType2["SELECT_FROM_UDM_DYNAMICALLY"] = "select_from_udm_dynamically";
  return DropdownCategoryType2;
})(DropdownCategoryType || {});

export { DropdownCategoryType };
