"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { SvgIcon, SvgIconType } from '../SvgIcon/index.mjs';
import classes from './styles.module.css.mjs';

function SegmentSeparator() {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      type: SvgIconType.ChevronRight,
      className: classes.segmentSeparator
    }
  );
}

export { SegmentSeparator };
