"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { useRef, useEffect } from 'react';

var EVENT_TYPE = /* @__PURE__ */ ((EVENT_TYPE2) => {
  EVENT_TYPE2["OPTION_DOWN"] = "option_down";
  EVENT_TYPE2["OPTION_UP"] = "option_up";
  EVENT_TYPE2["OPTION_SELECT"] = "option_select";
  EVENT_TYPE2["FORM_SUBMIT"] = "form_submit";
  return EVENT_TYPE2;
})(EVENT_TYPE || {});
const eventEmitter = {
  _events: {},
  _isExist(event) {
    return !!this?._events?.[event];
  },
  dispatch(event) {
    if (!this._isExist(event)) return;
    this._events[event].forEach(
      (callback) => callback()
    );
  },
  subscribe(event, callback) {
    if (!this._isExist(event)) this._events[event] = [];
    callback && this._events[event].push(callback);
  },
  unsubscribe(event, callback) {
    if (!this._isExist(event)) return;
    callback && (this._events[event] = this._events[event].filter((cb) => cb !== callback));
  }
};
function useOptionListKeyNavDispatch(ref) {
  return useKeyPressDispatch(ref, handleOptionListKeyNavigation);
}
function useKeyPressDispatch(ref, handler) {
  const savedHandler = useRef(handler);
  const targetElement = ref instanceof Element ? ref : ref?.current;
  return useEffect(() => {
    if (!targetElement || !targetElement?.addEventListener) {
      return () => {
      };
    }
    targetElement.addEventListener("keydown", savedHandler.current);
    return () => {
      targetElement.removeEventListener("keydown", savedHandler.current);
    };
  }, [targetElement, savedHandler]);
}
function useKeyboardEventSubscribe(event, callback) {
  return useEffect(() => {
    eventEmitter.subscribe(event, callback);
    return () => {
      eventEmitter.unsubscribe(event, callback);
    };
  }, [event, callback]);
}
const dispatch = (event) => eventEmitter.dispatch(event);
const handleOptionListKeyNavigation = (e) => {
  switch (e.key) {
    case "Enter":
      if (!e.shiftKey) {
        dispatch("option_select" /* OPTION_SELECT */);
        e.preventDefault();
        e.stopPropagation();
      }
      break;
    case "ArrowUp":
      dispatch("option_down" /* OPTION_DOWN */);
      break;
    case "ArrowDown":
      dispatch("option_up" /* OPTION_UP */);
      break;
  }
};

export { EVENT_TYPE, useKeyboardEventSubscribe, useOptionListKeyNavDispatch };
