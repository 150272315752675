"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import useEventStopPropagation from '../../../hooks/useEventStopPropagation.mjs';
import { ItemIcon, ItemChevronIcon, ItemCustomValue, ItemTitle } from '../../DropdownUI/DropdownUIElements.mjs';

function ComboBoxOption({
  option,
  selected,
  current = false,
  onSelect
}) {
  const handleOnSelect = useEventStopPropagation(() => {
    if (option.isSection && !option.drillDownOptionFactory) {
      return;
    }
    onSelect?.();
  });
  const iconProps = getIconProps(option);
  const valueProps = getValueProps(option);
  const hasChild = !!option.drillDownOptionFactory;
  const Component = option.isSection ? DropdownUI.HeaderItem : DropdownUI.Item;
  return /* @__PURE__ */ jsxs(
    Component,
    {
      selected,
      active: current,
      onClick: handleOnSelect,
      isHighlighted: option.isHighlighted,
      children: [
        /* @__PURE__ */ jsx(ItemIcon, { ...iconProps }),
        /* @__PURE__ */ jsx(OptionTitle, { ...valueProps }),
        hasChild && /* @__PURE__ */ jsx(ItemChevronIcon, {})
      ]
    }
  );
}
function OptionTitle({ isCustomValue, label, hint }) {
  if (!label && !hint) {
    return /* @__PURE__ */ jsx(Fragment, { children: "Please provide label for an option" });
  }
  const Component = isCustomValue ? ItemCustomValue : ItemTitle;
  return /* @__PURE__ */ jsx(Component, { hint, children: label });
}
function getIconProps(option) {
  const { iconType: type, iconUri: src } = option;
  return { type, src };
}
function getValueProps(option) {
  const { label, hint, isCustomValue } = option;
  return {
    label: label ? label : hint,
    hint: label ? hint : void 0,
    isCustomValue
  };
}

export { ComboBoxOption as default };
