"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { DropdownUIPopper } from '../../ComboBoxElements/Popper.mjs';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { useWindowEvent } from '../../../hooks/useWindowEvent.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { useDropdownStackContext, DropdownStackContextProvider } from '../context/combobox-dropdown-stack.mjs';
import { SearchInput } from './SearchInput.mjs';
import { ActiveCategoryContent } from './ActiveCategoryContent.mjs';

const Dropdown = forwardRef(
  ({
    referenceElement,
    topDropdownPanelComponent,
    autoFocusOnSearch,
    onOptionSelect,
    onClose
  }, ref) => {
    const { isClickInsideRefsAndChildren } = useDropdownStackContext(
      ref
    );
    useClick((event) => {
      if (!isClickInsideRefsAndChildren(event)) {
        onClose();
      }
    });
    useWindowEvent("keydown", (e) => {
      e.key === "Escape" && onClose();
    });
    const { availableCategories } = useComboBoxDropdownContext();
    if (availableCategories === null) {
      return null;
    }
    if (availableCategories?.length < 1) {
      return /* @__PURE__ */ jsx(DropdownStackContextProvider, { children: /* @__PURE__ */ jsx(DropdownUIPopper, { referenceElement, children: /* @__PURE__ */ jsx(DropdownUI, { ref, children: /* @__PURE__ */ jsx(DropdownUI.LoadingPanel, { children: "Loading options" }) }) }) });
    }
    return /* @__PURE__ */ jsx(DropdownStackContextProvider, { children: /* @__PURE__ */ jsx(DropdownUIPopper, { referenceElement, children: /* @__PURE__ */ jsxs(DropdownUI, { ref, children: [
      topDropdownPanelComponent && /* @__PURE__ */ jsx(Fragment, { children: topDropdownPanelComponent }),
      /* @__PURE__ */ jsx(SearchInput, { autoFocus: autoFocusOnSearch }),
      /* @__PURE__ */ jsx(ActiveCategoryContent, { onOptionSelect })
    ] }) }) });
  }
);
function useClick(cb) {
  useWindowEvent("mouseup", cb);
  useWindowEvent("touchend", cb);
}

export { Dropdown };
