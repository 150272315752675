"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { CATEGORY_COMPONENTS } from './categoryContentMapping.mjs';
import '../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import '../context/combobox-dropdown-search.mjs';
import '../context/combobox-dropdown-stack.mjs';
import '@integration-app/sdk';
import '../../DropdownUI/index.mjs';
import '../../../_modules/react-use-promise.mjs';
import '../../Radio/index.mjs';
import '../../../contexts/integration-app-client-context.mjs';
import '../../../contexts/integration-app-connection-context.mjs';
import '../../../contexts/integration-app-integration-context.mjs';
import 'js-convert-case';
import '../../DataBuilder/field-context.mjs';
import 'react';
import '../Value/Schema.mjs';
import '../../DataBuilderUI/index.mjs';
import '../../DataBuilder/data-builder-context.mjs';
import '../../SvgIcon/index.mjs';
import 'clsx';
import '../../InlineEditable.module.css.mjs';
import '../../../ui-kit/styled/text.mjs';
import '../../Tooltip/index.mjs';
import '../../DataBuilder/FieldTypeSelector/index.mjs';
import '../Edit/OptionSelector.mjs';
import '../../DataBuilder/Formula/case.mjs';
import '../../DataBuilder/Formula/concat.mjs';
import '../../DataBuilder/Formula/copy.mjs';
import '../../DataBuilder/Formula/domainFromEmail.mjs';
import '../../DataBuilder/Formula/extractDate.mjs';
import '../../DataBuilder/Formula/extractTime.mjs';
import '../../DataBuilder/Formula/findAppRecordId.mjs';
import '../../DataBuilder/Formula/findExternalRecordId.mjs';
import '../../DataBuilder/Formula/firstName.mjs';
import '../../DataBuilder/Formula/firstNotEmpty.mjs';
import '../../DataBuilder/Formula/iterate.mjs';
import '../../DataBuilder/Formula/jsonata.mjs';
import '../../DataBuilder/Formula/lastName.mjs';
import '../../DataBuilder/Formula/lookup.mjs';
import '../../DataBuilder/Formula/map.mjs';
import '../../DataBuilder/Formula/mergeObjects.mjs';
import '../../DataBuilder/Formula/record.mjs';
import '../../DataBuilder/Formula/tpl.mjs';
import '../../DataBuilder/Formula/var.mjs';
import '../../ComboBoxElements/TipTapEditor/Editor.mjs';
import '../../ComboBoxElements/TipTapEditor/EditorStandAlone.mjs';
import '../../ComboBoxElements/TipTapEditor/TagComponent.mjs';
import 'fuse';
import '../../ComboBoxElements/FieldWrapper.mjs';
import './Dropdown.mjs';
import '../../Tag/styles.module.css.mjs';
import '../Value/index.mjs';
import '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import '../../../styled-system/jsx/is-valid-prop.mjs';
import '../../Tag/TagPlaceholder.mjs';
import '../../DataBuilder/Nested/FieldListWrapper/FieldListWrapper.module.css.mjs';
import '../../DataBuilder/FieldValue/ScalarArray.module.css.mjs';
import 'fast-deep-equal';
import '../../DataBuilder/styles.module.css.mjs';
import './Categories/Collections.module.css.mjs';
import '../../Button/Button.mjs';
import './Categories/LookupOptions/UiComponents.module.css.mjs';

function ActiveCategoryContent({
  onOptionSelect
}) {
  const { activeCategory } = useComboBoxDropdownContext();
  const CategoryContainer = (activeCategory ? CATEGORY_COMPONENTS[activeCategory] : void 0) ?? (() => /* @__PURE__ */ jsx("p", { children: "Unknown category" }));
  return /* @__PURE__ */ jsx(CategoryContainer, { onOptionSelect });
}

export { ActiveCategoryContent };
