/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".DropdownUIElements-module_dropdownElement_tabIcon__9yyA7 {\n    display: inline-block;\n    height: 1rem;\n    width: 1rem\n}\n\n.DropdownUIElements-module_dropdownElement_itemIcon__PP4ay {\n    flex-shrink: 0;\n    flex-grow: 0;\n    height: 1rem;\n    width: 1rem\n}\n\n.DropdownUIElements-module_dropdownElement_itemChevron__1AIlQ {\n    flex-shrink: 0;\n    flex-grow: 0;\n    margin-left: 0.25rem;\n    height: 1rem;\n    width: 1rem\n}\n\n.DropdownUIElements-module_dropdownElement_itemTitle__PLvD5 {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n    gap: 0.125rem;\n    max-width: 100%;\n    overflow-wrap: break-word\n}\n\n.DropdownUIElements-module_dropdownElement_itemTitle__PLvD5 > small {\n    font-size: 0.813rem;\n    font-style: italic;\n    line-height: 1.2\n}\n\n.DropdownUIElements-module_dropdownElement_itemCustomValue__LEA9Y {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    justify-content: space-between;\n    gap: 1rem\n}\n\n.DropdownUIElements-module_dropdownElement_itemCustomValue__LEA9Y small {\n    font-size: 0.75rem;\n    font-style: italic;\n    line-height: 1.2;\n    margin-left: auto\n}\n";
var classes = {"dropdownElement_tabIcon":"DropdownUIElements-module_dropdownElement_tabIcon__9yyA7","dropdownElement_itemIcon":"DropdownUIElements-module_dropdownElement_itemIcon__PP4ay","dropdownElement_itemChevron":"DropdownUIElements-module_dropdownElement_itemChevron__1AIlQ","dropdownElement_itemTitle":"DropdownUIElements-module_dropdownElement_itemTitle__PLvD5","dropdownElement_itemCustomValue":"DropdownUIElements-module_dropdownElement_itemCustomValue__LEA9Y"};
styleInject(css_248z);

export { classes as default };
