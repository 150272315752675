"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { SvgIcon, SvgIconType } from '../SvgIcon/index.mjs';
import { Truncate } from '../Truncate/index.mjs';
import classes from './DropdownUIElements.module.css.mjs';
import clsx from 'clsx';
import '@integration-app/sdk';
import '../../contexts/integration-app-context.mjs';
import 'swr';
import '../../_modules/awesome-debounce-promise.mjs';
import 'react';
import 'swr/infinite';
import 'query-string';
import 'react-icons/tb';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import '../../ui-kit/avatar.mjs';
import '../../ui-kit/styled/icon.mjs';
import '../Integrations/IntegrationItem/index.mjs';
import '../Integrations/IntegrationItemSkeleton.mjs';
import 'js-convert-case';
import '../../ui-kit/styled/text.mjs';
import '../Tooltip/index.mjs';
import 'fast-deep-equal';
import '../ComboBox/Value/Schema.mjs';
import '../DataBuilderUI/index.mjs';
import '../DataBuilder/data-builder-context.mjs';
import '../DataBuilder/field-context.mjs';
import '../InlineEditable.module.css.mjs';
import '../DataBuilder/FieldTypeSelector/index.mjs';
import '../ComboBox/context/combobox-context.mjs';
import '../ComboBox/context/combobox-dropdown-context.mjs';
import '../ComboBox/context/combobox-dropdown-search.mjs';
import { useDropdownItemContext } from './index.mjs';
import '../../_modules/react-use-promise.mjs';
import '../Radio/index.mjs';
import '../../contexts/integration-app-client-context.mjs';
import '../../contexts/integration-app-connection-context.mjs';
import '../../contexts/integration-app-integration-context.mjs';
import '../ComboBox/Dropdown/Categories/Collections.module.css.mjs';
import '../ComboBox/context/combobox-dropdown-stack.mjs';
import '../Button/Button.mjs';
import '../ComboBox/Dropdown/Categories/LookupOptions/UiComponents.module.css.mjs';
import '../ComboBox/Dropdown/categoryContentMapping.mjs';
import '../ComboBox/Edit/OptionSelector.mjs';
import '../DataBuilder/Formula/case.mjs';
import '../DataBuilder/Formula/concat.mjs';
import '../DataBuilder/Formula/copy.mjs';
import '../DataBuilder/Formula/domainFromEmail.mjs';
import '../DataBuilder/Formula/extractDate.mjs';
import '../DataBuilder/Formula/extractTime.mjs';
import '../DataBuilder/Formula/findAppRecordId.mjs';
import '../DataBuilder/Formula/findExternalRecordId.mjs';
import '../DataBuilder/Formula/firstName.mjs';
import '../DataBuilder/Formula/firstNotEmpty.mjs';
import '../DataBuilder/Formula/iterate.mjs';
import '../DataBuilder/Formula/jsonata.mjs';
import '../DataBuilder/Formula/lastName.mjs';
import '../DataBuilder/Formula/lookup.mjs';
import '../DataBuilder/Formula/map.mjs';
import '../DataBuilder/Formula/mergeObjects.mjs';
import '../DataBuilder/Formula/record.mjs';
import '../DataBuilder/Formula/tpl.mjs';
import '../DataBuilder/Formula/var.mjs';
import '../ComboBoxElements/TipTapEditor/Editor.mjs';
import '../ComboBoxElements/TipTapEditor/EditorStandAlone.mjs';
import '../ComboBoxElements/TipTapEditor/TagComponent.mjs';
import 'fuse';
import '../ComboBoxElements/FieldWrapper.mjs';
import '../ComboBox/Dropdown/Dropdown.mjs';
import '../Tag/styles.module.css.mjs';
import '../ComboBox/Value/index.mjs';
import '../Tag/TagPlaceholder.mjs';
import '../DataBuilder/Nested/FieldListWrapper/FieldListWrapper.module.css.mjs';
import '../DataBuilder/FieldValue/ScalarArray.module.css.mjs';
import '../DataBuilder/styles.module.css.mjs';
import '../ComboBoxElements/ComboBoxTag.mjs';
import 'react-popper';
import 'react-portal';
import 'usehooks-ts';
import '../ComboBoxElements/Popper.module.css.mjs';
import '../IntegrationElements/integration-element-context.mjs';
import '../Input/Input.mjs';
import '../Input/SearchInput.mjs';
import '../SimpleInput/SimpleInput.module.css.mjs';
import '../Textarea/index.mjs';
import '../Notification/Notification.module.css.mjs';
import '../Tag/index.mjs';
import '@headlessui/react';
import '../Toggle/Toggle.module.css.mjs';
import '../Popover/index.mjs';
import '../SimpleMenu/index.mjs';

function GenericSvgIcon({ type, ...props }) {
  return /* @__PURE__ */ jsx(SvgIcon, { type, ...props });
}
function GenericImgIcon({ src, ...props }) {
  return /* @__PURE__ */ jsx("img", { src, alt: "", ...props });
}
function TabIcon({ className, ...props }) {
  if ("type" in props && props?.type) {
    return /* @__PURE__ */ jsx(
      GenericSvgIcon,
      {
        className: clsx(classes.dropdownElement_tabIcon, className),
        ...props
      }
    );
  }
  if ("src" in props && props?.src) {
    return /* @__PURE__ */ jsx(
      GenericImgIcon,
      {
        className: clsx(classes.dropdownElement_tabIcon, className),
        ...props
      }
    );
  }
  return null;
}
function ItemIcon({ className, ...props }) {
  if ("type" in props && props?.type) {
    return /* @__PURE__ */ jsx(
      GenericSvgIcon,
      {
        className: clsx(classes.dropdownElement_itemIcon, className),
        ...props
      }
    );
  }
  if ("src" in props && props?.src) {
    return /* @__PURE__ */ jsx(
      GenericImgIcon,
      {
        className: clsx(classes.dropdownElement_itemIcon, className),
        ...props
      }
    );
  }
  return null;
}
function ItemChevronIcon({ open }) {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classes.dropdownElement_itemChevron,
      type: open ? SvgIconType.ChevronDown : SvgIconType.ChevronRight
    }
  );
}
function ItemBackIcon() {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classes.dropdownElement_itemIcon,
      type: SvgIconType.ChevronLeft
    }
  );
}
function ItemTitle({
  children,
  hint
}) {
  const { id } = useDropdownItemContext();
  return /* @__PURE__ */ jsxs("div", { className: classes.dropdownElement_itemTitle, id, children: [
    /* @__PURE__ */ jsx(Truncate, { mode: "end", children }),
    hint && /* @__PURE__ */ jsx("small", { children: hint })
  ] });
}
function ItemCustomValue({
  children,
  hint
}) {
  return /* @__PURE__ */ jsxs("div", { className: classes.dropdownElement_itemCustomValue, children: [
    children,
    hint && /* @__PURE__ */ jsx("small", { children: hint })
  ] });
}

export { ItemBackIcon, ItemChevronIcon, ItemCustomValue, ItemIcon, ItemTitle, TabIcon };
