"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import deepEqual from 'fast-deep-equal';
import { DropdownUI } from '../../DropdownUI/index.mjs';
import { SvgIconType } from '../../SvgIcon/index.mjs';
import { useDeepEffect } from '../../../hooks/useDeepEffect.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import { useComboBoxDropdownSearchContext } from '../context/combobox-dropdown-search.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../hooks/keyboard-events.mjs';
import ComboBoxOption from './Option.mjs';
import useDataBuilder from '../../DataBuilder/data-builder-context.mjs';
import useDataBuilderField from '../../DataBuilder/field-context.mjs';
import { toSnakeCase } from 'js-convert-case';
import { useComboBox } from '../context/combobox-context.mjs';
import { ItemIcon, ItemTitle } from '../../DropdownUI/DropdownUIElements.mjs';

function OptionsList({
  onOptionSelect
}) {
  const { onAddVariable } = useDataBuilder();
  const { field } = useDataBuilderField();
  const {
    isOptionSelected,
    generateOptions,
    drillDownOptionsStack,
    drillDown,
    drillUp,
    forceActiveCategory
  } = useComboBoxDropdownContext();
  const { searchValue, searchSource } = useComboBoxDropdownSearchContext();
  const [currentOption, setCurrentOption] = useState(
    null
  );
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_DOWN, makePreviousOptionCurrent);
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_UP, makeNextOptionCurrent);
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_SELECT, handleKeyEnter);
  const { value: parentInputValue } = useComboBox();
  const canUseParentInputSearchValue = !searchValue && typeof parentInputValue === "string" && !!parentInputValue && drillDownOptionsStack.length === 0 && searchSource === "editor";
  const [options, optionsError, optionsState] = generateOptions(
    canUseParentInputSearchValue ? parentInputValue : searchValue,
    drillDownOptionsStack
  );
  useDeepEffect(() => {
    if (!options?.length) {
      return;
    }
    if (!currentOption || getOptionIndex(currentOption) == -1) {
      const selectedOption = options.find(isOptionSelected);
      if (selectedOption) {
        setCurrentOption(selectedOption);
      }
    }
  }, [options]);
  const makeOptionComparable = (option) => {
    return option && {
      ...option,
      label: option.searchLabel ?? option.label?.toString()
    };
  };
  function getOptionIndex(option) {
    return options.findIndex(
      (o) => deepEqual(makeOptionComparable(o), makeOptionComparable(option))
    );
  }
  function makeNextOptionCurrent() {
    let idx = getOptionIndex(currentOption);
    while (idx < options.length - 1) {
      idx += 1;
      const currentOption2 = options[idx];
      if (optionCanBeSelected(currentOption2)) {
        setCurrentOption(currentOption2);
        break;
      }
    }
  }
  function makePreviousOptionCurrent() {
    let idx = getOptionIndex(currentOption);
    while (idx > 0) {
      idx -= 1;
      const currentOption2 = options[idx];
      if (optionCanBeSelected(currentOption2)) {
        setCurrentOption(currentOption2);
        break;
      }
    }
  }
  function optionCanBeSelected(option) {
    return !option.isSection;
  }
  function handleKeyEnter() {
    if (currentOption) {
      handleOptionSelect(currentOption);
    }
  }
  function handleOptionSelect(option) {
    if (option.addVariable) {
      const objectLocator = option.addVariable;
      const variableLocator = `${objectLocator}.${field.key || toSnakeCase(field.name) || toSnakeCase(field?.schema?.title)}`;
      onAddVariable?.(variableLocator, field.schema ?? {});
      onOptionSelect({
        $var: variableLocator
      });
    } else if (option.drillDownOptionFactory) {
      drillDown(option);
    } else if (option.drillUp) {
      drillUp();
    } else if (option.categorySelect) {
      forceActiveCategory(option.categorySelect);
    } else {
      onOptionSelect(option.value);
    }
  }
  return /* @__PURE__ */ jsx(DropdownList, { state: optionsState, error: optionsError, children: /* @__PURE__ */ jsx(
    DropdownListOptions,
    {
      searchTerm: canUseParentInputSearchValue ? parentInputValue : void 0,
      options,
      currentOption,
      isOptionSelected,
      handleOptionSelect
    }
  ) });
}
function DropdownList({
  state,
  error,
  children
}) {
  if (state === "rejected" || error) {
    return /* @__PURE__ */ jsx(DropdownUI.List, { children: /* @__PURE__ */ jsxs(DropdownUI.Item, { hasError: true, children: [
      /* @__PURE__ */ jsx(ItemIcon, { type: SvgIconType.AlertTriangle }),
      /* @__PURE__ */ jsx(ItemTitle, { children: error?.message || "Something went wrong" })
    ] }) });
  }
  if (state === "pending") {
    return /* @__PURE__ */ jsx(DropdownUI.List, { children: /* @__PURE__ */ jsxs(DropdownUI.Item, { readonly: true, children: [
      /* @__PURE__ */ jsx(ItemIcon, { type: SvgIconType.Loader }),
      /* @__PURE__ */ jsx(ItemTitle, { children: "Loading" })
    ] }) });
  }
  return /* @__PURE__ */ jsx(DropdownUI.List, { children });
}
function DropdownListOptions({
  options,
  currentOption,
  isOptionSelected,
  handleOptionSelect,
  searchTerm
}) {
  if (!options) {
    return null;
  }
  if (options.length === 0) {
    return /* @__PURE__ */ jsx(DropdownUI.Item, { readonly: true, children: /* @__PURE__ */ jsxs(ItemTitle, { children: [
      "No results found ",
      searchTerm ? `for: ${searchTerm}` : ""
    ] }) });
  }
  return /* @__PURE__ */ jsx(Fragment, { children: options.map((option, idx) => /* @__PURE__ */ jsx(
    ComboBoxOption,
    {
      option,
      selected: isOptionSelected(option),
      current: deepEqual(option, currentOption),
      onSelect: () => handleOptionSelect(option)
    },
    idx
  )) });
}

export { OptionsList };
