"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import usePromise from '../../../_modules/react-use-promise.mjs';
import deepEqual from 'fast-deep-equal';
import { SvgIconType } from '../../SvgIcon/index.mjs';
import { useIntegrationAppClient } from '../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration } from '../../../contexts/integration-app-integration-context.mjs';
import 'fuse';
import { BooleanOptionsFactory } from '../options-factories/boolean.mjs';
import { ConstantOptionFactory } from '../options-factories/constant.mjs';
import { DropdownCategoryType } from '../types.mjs';
import '@integration-app/sdk';
import '../Dropdown/categoryContentMapping.mjs';
import { EnumOptionFactory } from '../options-factories/enum.mjs';
import 'js-convert-case';
import '../../Tag/styles.module.css.mjs';
import { ReferenceRecordsOptionsFactory } from '../options-factories/reference-records.mjs';
import { ReferenceUdmOptionFactory } from '../options-factories/reference-udm.mjs';
import { getAvailableRootCategories, getCurrentCategory } from './helpers.mjs';
import { useComboBox } from './combobox-context.mjs';
import useDataBuilder from '../../DataBuilder/data-builder-context.mjs';
import { RootOptionFactory } from '../options-factories/root.mjs';
import { useComboBoxDropdownSearchContext } from './combobox-dropdown-search.mjs';

const ComboBoxDropdownContext = createContext({
  availableCategories: [],
  activeCategory: DropdownCategoryType.ROOT,
  drillDownOptionsStack: [],
  drillDown: () => {
  },
  drillUp: () => {
  },
  isOptionSelected: () => false,
  generateOptions: () => [[]],
  forceActiveCategory: () => {
  }
});
ComboBoxDropdownContext.displayName = "ComboBoxDropdownContext";
const ComboBoxDropdownContextProvider = ({
  children
}) => {
  const { editableVariablesSchemaLocators } = useDataBuilder();
  const {
    value,
    schema,
    valueSpec,
    variablesSchema,
    variablesOnly,
    options,
    optionFactories,
    isDropdownOpen
  } = useComboBox();
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const { integrationId } = useIntegrationAppIntegration();
  const { setSearchValue } = useComboBoxDropdownSearchContext();
  const [drillDownOptionsStack, setDrillDownOptionsStack] = useState([]);
  const [availableCategories, setAvailableCategories] = useState(null);
  const [forcedActiveCategory, setForcedActiveCategory] = useState(void 0);
  const hasVariables = variablesSchema?.type === "object" && Object.keys(variablesSchema.properties ?? {}).length > 0;
  useEffect(() => {
    void getAvailableRootCategories({
      schema,
      options,
      optionFactories,
      variablesOnly,
      hasVariables,
      client,
      connectionId,
      integrationId
    }).then((categories) => {
      setAvailableCategories(categories);
    });
  }, [
    schema,
    options,
    optionFactories,
    variablesOnly,
    hasVariables,
    client,
    integrationId,
    connectionId
  ]);
  const [currentDrillDownOption] = drillDownOptionsStack.toReversed();
  const currentFactory = currentDrillDownOption?.drillDownOptionFactory;
  const activeCategory = availableCategories ? forcedActiveCategory || getCurrentCategory(
    availableCategories,
    currentFactory?.categoryType,
    value
  ) : null;
  function drillDown(option) {
    setSearchValue("");
    const newStack = [...drillDownOptionsStack, option];
    setDrillDownOptionsStack(newStack);
  }
  function drillUp() {
    const newStack = [...drillDownOptionsStack];
    newStack.pop();
    setDrillDownOptionsStack(newStack);
  }
  function resetDrillDown() {
    setDrillDownOptionsStack([]);
  }
  useEffect(() => {
    if (!isDropdownOpen && drillDownOptionsStack.length !== 0) {
      resetDrillDown();
    }
    if (!isDropdownOpen) {
      setForcedActiveCategory(void 0);
    }
  }, [isDropdownOpen]);
  const generateOptions = function(searchValue, drillDownStack) {
    return usePromise(
      async () => getOptions({
        availableCategories,
        schema,
        variablesSchema,
        searchValue,
        valueSpec,
        drillDownStack,
        staticOptions: options,
        optionFactories,
        editableVariablesSchemaLocators
      }),
      [
        activeCategory,
        JSON.stringify(availableCategories),
        JSON.stringify(schema),
        JSON.stringify(variablesSchema),
        searchValue,
        JSON.stringify(valueSpec),
        drillDownStack.length.toString(),
        JSON.stringify(options),
        optionFactories?.length,
        JSON.stringify(editableVariablesSchemaLocators)
      ]
    );
  };
  const isOptionSelected = useCallback(
    function(option) {
      return deepEqual(option.value, value);
    },
    [value]
  );
  return /* @__PURE__ */ jsx(
    ComboBoxDropdownContext.Provider,
    {
      value: {
        availableCategories,
        activeCategory,
        drillDownOptionsStack,
        drillDown,
        drillUp,
        isOptionSelected,
        generateOptions,
        forceActiveCategory: (category) => {
          setForcedActiveCategory(category);
        }
      },
      children
    }
  );
};
function useComboBoxDropdownContext() {
  return useContext(ComboBoxDropdownContext);
}
async function getOptions({
  availableCategories,
  schema,
  variablesSchema,
  searchValue,
  valueSpec,
  drillDownStack,
  staticOptions,
  optionFactories = [],
  editableVariablesSchemaLocators
}) {
  const factories = [...optionFactories];
  if (drillDownStack.length === 0) {
    factories.push(new RootOptionFactory(availableCategories));
    if (schema?.referenceUdm) {
      factories.unshift(new ReferenceUdmOptionFactory(schema));
    } else if (schema?.referenceRecords) {
      factories.push(new ReferenceRecordsOptionsFactory({ schema }));
    } else if (schema?.enum) {
      factories.push(new EnumOptionFactory({ schema }));
    } else if (schema?.type === "boolean") {
      factories.push(new BooleanOptionsFactory());
    } else if (staticOptions) {
      factories.push(new ConstantOptionFactory(staticOptions));
    }
  }
  const options = [];
  async function getOptionsFromFactory(factory) {
    const options2 = [];
    options2.push(
      ...await factory.getOptions({
        input: searchValue,
        spec: valueSpec,
        editableVariablesSchemaLocators,
        variablesSchema
      })
    );
    if (searchValue) {
      const unfilteredOptions = await factory.getOptions({
        input: "",
        spec: valueSpec,
        editableVariablesSchemaLocators,
        variablesSchema
      });
      for (const option of unfilteredOptions) {
        if (option.drillDownOptionFactory) {
          options2.push(
            ...await getOptionsFromFactory(option.drillDownOptionFactory)
          );
        }
      }
    }
    return options2;
  }
  if (drillDownStack.length > 0) {
    options.push({
      label: "Back",
      iconType: SvgIconType.ChevronLeft,
      drillUp: true
    });
    const option = drillDownStack[drillDownStack.length - 1];
    if (option?.drillDownOptionFactory) {
      options.push(
        ...await getOptionsFromFactory(option?.drillDownOptionFactory)
      );
    }
  } else {
    const localOptions = [];
    for (const optionFactory of [...factories]) {
      localOptions.push(...await getOptionsFromFactory(optionFactory));
    }
    options.push(...localOptions);
  }
  return options;
}

export { ComboBoxDropdownContextProvider, useComboBoxDropdownContext };
