"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { DropdownCategoryType } from '../types.mjs';
import { filterOptionsByLabel } from './utils.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, key + "" , value);
class EnumOptionFactory {
  constructor(params) {
    this.params = params;
    __publicField(this, "categoryType", DropdownCategoryType.OPTIONS);
  }
  async getOptions({
    input
  }) {
    return this.getOptionsSync({ input });
  }
  getOptionsSync({ input }) {
    const options = [];
    if (this.params.schema.enum && Array.isArray(this.params.schema.enum)) {
      this.params.schema.enum.map((enumValue) => {
        if (typeof enumValue == "object" && enumValue.value) {
          options.push({
            label: enumValue.label ?? enumValue.value,
            value: enumValue.value
          });
        } else {
          options.push({
            label: enumValue,
            value: enumValue
          });
        }
      });
    }
    const filteredOptions = filterOptionsByLabel(options, input);
    if (filteredOptions.length > 0) {
      return [
        {
          label: "Possible Values",
          iconUri: this.params.iconUri,
          isSection: true
        },
        ...filteredOptions
      ];
    } else {
      return [];
    }
  }
}

export { EnumOptionFactory };
