"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { UNIFIED_DATA_MODELS } from '@integration-app/sdk';
import { SvgIconType } from '../../SvgIcon/index.mjs';
import { DropdownCategoryType } from '../types.mjs';
import { filterOptionsByLabel } from './utils.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, key + "" , value);
class ReferenceUdmOptionFactory {
  constructor(schema) {
    this.schema = schema;
    __publicField(this, "categoryType", DropdownCategoryType.OPTIONS);
  }
  async getOptions({
    input
  }) {
    const options = [];
    const udm = this.schema?.referenceUdm;
    const udmSpec = UNIFIED_DATA_MODELS[udm];
    if (udmSpec) {
      options.push({
        label: "Select Dynamically Using Variables",
        iconType: SvgIconType.Search,
        value: {
          $lookup: {}
        }
      });
    }
    return filterOptionsByLabel(options, input);
  }
}

export { ReferenceUdmOptionFactory };
