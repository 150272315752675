"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { EditorContent, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import TagComponent, { getComponentTagNode } from './TagComponent.mjs';
import { KeyboardShortcutHandler } from './extension-keyboardShortcuts.mjs';
import classes from './Editor.module.css.mjs';
import { getSchemaNodesSummary } from './helpers/common.mjs';
import { transformValueToEditorSchema } from './helpers/transformValueToEditorSchema.mjs';
import { transformEditorSchemaToValue } from './helpers/transformEditorSchemaToValue.mjs';

const extensions = [History, Document, Paragraph, Text, TagComponent];
const clearEditorSearchTerm = (chain, content, allowOverride) => {
  if (!allowOverride) {
    return chain;
  }
  const nodesSummary = getSchemaNodesSummary(content ?? {});
  if (nodesSummary.nonText === 0) {
    return chain?.setContent("");
  }
  return chain;
};
function useComboboxEditor({
  value,
  onEnter,
  onChange,
  placeholder = "",
  allowEditorSearchClear = false,
  ...props
}) {
  const editorExtensions = [
    ...extensions,
    Placeholder.configure({ placeholder }),
    KeyboardShortcutHandler({ onEnter })
  ];
  const editorRef = useRef(null);
  const onChangeHandlerRef = useRef(onChange);
  onChangeHandlerRef.current = onChange;
  const valueRef = useRef(value);
  valueRef.current = value;
  function handleCreate({ editor }) {
    editorRef.current = editor;
    setContent(transformValueToEditorSchema(valueRef.current));
  }
  function handleUpdate() {
    if (!editorRef.current) return;
    const newValue = transformEditorSchemaToValue(
      editorRef.current.getJSON() ?? {}
    );
    if (newValue !== valueRef.current) {
      onChangeHandlerRef.current?.(newValue);
    }
  }
  useEffect(() => {
    if (editorRef.current) {
      setContent?.(transformValueToEditorSchema(value));
    }
  }, [value ? JSON.stringify(value) : void 0]);
  useEditor({
    extensions: editorExtensions,
    ...props,
    // Initially always set content to undefined. We will manage content via setContent
    onUpdate: handleUpdate,
    onCreate: handleCreate
  });
  function pasteTag(variable, props2) {
    const id = (/* @__PURE__ */ new Date()).getTime().toString();
    const chain = editorRef.current?.chain().focus();
    if (!chain) {
      return;
    }
    clearEditorSearchTerm(
      chain,
      editorRef.current?.getJSON(),
      allowEditorSearchClear
    ).insertContent(getComponentTagNode(id, variable, props2)).run();
    handleUpdate();
  }
  function setContent(content) {
    const contentFromEditor = editorRef.current?.getJSON() ?? {};
    if (!editorRef.current) {
      return;
    }
    if (JSON.stringify(content) !== JSON.stringify(contentFromEditor)) {
      const { from, to } = editorRef.current?.state?.selection ?? {};
      editorRef.current?.commands.setContent(content);
      from && to && editorRef.current.commands.setTextSelection({ from, to });
    }
  }
  return {
    editor: editorRef.current,
    pasteTag,
    setContent
  };
}
const Editor = forwardRef(
  ({ editor, className, ...props }, ref) => {
    return /* @__PURE__ */ jsx(
      EditorContent,
      {
        className: clsx(classes.editor, className),
        editor,
        ...props,
        ref
      }
    );
  }
);

export { Editor, useComboboxEditor };
