"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { DropdownCategoryType } from '../types.mjs';
import { filterOptionsByLabel } from './utils.mjs';

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, key + "" , value);
class ConstantOptionFactory {
  constructor(options) {
    this.options = options;
    __publicField(this, "categoryType", DropdownCategoryType.OPTIONS);
  }
  async getOptions({
    input
  }) {
    return this.getOptionsSync({ input });
  }
  getOptionsSync({ input }) {
    return filterOptionsByLabel(this.options, input);
  }
}

export { ConstantOptionFactory };
