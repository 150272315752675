"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { getComponentTagNode } from '../TagComponent.mjs';
import { transformValueToTpl, getTemplateFromTplValue, getValuesFromTplValue, splitStringToChunks, isTagChunk, getTagChunkValue, getTextNode } from './common.mjs';

function transformValueToEditorSchema(value) {
  const safeValue = transformValueToTpl(value);
  const template = getTemplateFromTplValue(safeValue);
  const values = getValuesFromTplValue(safeValue);
  return {
    type: "doc",
    content: getDocumentContent(template, values)
  };
}
function getDocumentContent(string, values = {}) {
  return string.split("\n").map((line) => ({
    type: "paragraph",
    content: getParagraphContent(line, values)
  }));
}
function getParagraphContent(string, values = {}) {
  if (string === "") {
    return [];
  }
  const chunks = splitStringToChunks(string);
  return chunks.map((chunk) => transformStringToNode(chunk, values)).filter((x) => !!x);
}
function transformStringToNode(string, values = {}) {
  if (!string) {
    return null;
  }
  if (isTagChunk(string)) {
    const id = getTagChunkValue(string);
    const value = values[id];
    return value ? getComponentTagNode(id, value) : getTextNode(string);
  }
  return getTextNode(string);
}

export { getDocumentContent, getParagraphContent, transformValueToEditorSchema };
